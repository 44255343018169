import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const LogBookSetup = () => {
  return (
    <Layout>
      <Seo
        title="How to Setup Logbook in Synkli App"
        description={`Learn how to easily set up a logbook in the Synkli app to track car mileage. Follow these step-by-step instructions to streamline your trip logbook.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`logbook-setup`} />
    </Layout>
  )
}

export default LogBookSetup
